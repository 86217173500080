import React from "react";
import "./about.scss";

export class About extends React.Component<{}, {}> {
    render() {
        return (
            <div className="about-container">
                <p>
                    Netflix Top Rated cross-references IMDB and Rotton Tomato
                    ratings with what's currently availible on Netflix.
                </p>
                <p>
                    Currently this site covers <b>Netflix USA</b> as well as <b>Netflix Canada</b>. Send a
                    request to contact@netflixtoprated.com to have your country
                    added.
                </p>
            </div>
        );
    }
}
