import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ContentList } from "./components/ContentList";
import { About } from "./components/About";

export class App extends React.Component<{}> {
    render() {
        return (
            <div className="App">
                <Router>
                    <div>
                        <header className="App-header">
                            <span role="img" aria-label="clapper">🎬</span> Netflix Top Rated
                            <nav>
                                <ul>
                                    <li><Link to="/usa/">USA</Link></li>
                                    <li><Link to="/canada/">Canada</Link></li>
                                    <li><Link to="/about/">About</Link></li>
                                </ul>
                            </nav>
                        </header>

                        <Route path="/" exact component={() => <ContentList country={"usa"}/>} />
                        <Route path="/about/" component={About} />
                        <Route path="/usa/" component={() => <ContentList country={"usa"}/>} />
                        <Route path="/canada/" component={() => <ContentList country={"canada"}/>} />
                    </div>
                </Router>

                <div className="footer">contact@netflixtoprated.com </div>
            </div>
        );
    }
}

export default App;
