import * as firebase from "firebase/app";
import "firebase/firestore";

const config = {
    authDomain: "top-rated-on-netflix.firebaseapp.com",
    databaseURL: "https://top-rated-on-netflix.firebaseio.com",
    projectId: "top-rated-on-netflix",
    storageBucket: "top-rated-on-netflix.appspot.com"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const db = firebase.firestore();

// Disable deprecated features
db.settings({
    timestampsInSnapshots: true
});
