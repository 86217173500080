import React from "react";
import { db } from "../firebase";

export type Country = 'canada' | 'usa' ;

interface IContentListProps {
    country?: Country;
}

interface ContentListState {
    netflixMovies: string[];
}

export class ContentList extends React.Component<IContentListProps, ContentListState> {
    componentDidMount() {
        this.getNetflixContent();
    }

    private getNetflixContent = () => {
        if (this.props.country != null){
            db.collection("movie-list")
                .where("country", "==", this.props.country)
                .orderBy("created", "desc")
                .limit(1)
                .get()
                .then((querySnapshot: any) => {
                    querySnapshot.forEach((doc: any) => {
                        this.setState({ netflixMovies: doc.data().orderedMovies });
                    });
                }).catch(e => console.error(e));
        } else {
            db.collection("movie-list")
                .orderBy("created", "desc")
                .limit(1)
                .get()
                .then((querySnapshot: any) => {
                    querySnapshot.forEach((doc: any) => {
                        this.setState({ netflixMovies: doc.data().orderedMovies });
                    });
                }).catch(e => console.error(e));
        }
    };

    private renderNetflixContent = () => {
        if (this.state == null || this.state.netflixMovies == null) {
            return <div>Loading...</div>;
        }

        return (
            <div className="App-content-list">
                <div className="grid-container">
                    {this.state.netflixMovies.map((flick, i) => (
                        <React.Fragment>
                            <div className="grid-item" key={flick + i + 1}>
                                {i + 1}.
                            </div>
                            <div className="grid-item" key={flick}>
                                {flick}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className="description">
                    Netflix content, cross referenced with IMBD & Rotten Tomato.
                    Updated daily.
                </div>
                {this.state != null && this.state.netflixMovies != null ? (
                    this.renderNetflixContent()
                ) : (
                    <div className="loader">Loading...</div>
                )}
            </React.Fragment>
        );
    }
}
